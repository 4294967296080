@import 'normalize.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

:root{
    --primary: #333333;
}

html{
    font-size: 62.5%;
}

body{
    // font-family: 'Montserrat', sans-serif;
    font-size: 1.6rem;
    text-rendering: optimizeSpeed;
}